//@ts-nocheck
import React, { useEffect, useState } from "react";
import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import { ChevronDownIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import "./Accordion.styles.css";

export type AccordionComponentProp = {
  triggerElement: any;
  identifier?: string;
  content: any;
  onClick?: (event: any) => void;
};

export type AccordionComponentProps = {
  sections: AccordionComponentProp[];
  options?: {
    multiple?: boolean;
    collapsible?: boolean;
    defaultOpenSection?: number;
    contentClass?: string;
  };
};

const AccordionTrigger = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Header className="AccordionHeader">
      <Accordion.Trigger
        className={classNames("AccordionTrigger", className)}
        {...props}
        ref={forwardedRef}
      >
        <ChevronRightIcon className="AccordionChevron" aria-hidden />
        {children}
      </Accordion.Trigger>
    </Accordion.Header>
  )
);
const AccordionContent = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
      className={classNames("AccordionContent", className)}
      {...props}
      ref={forwardedRef}
    >
      <div className="AccordionContentText">{children}</div>
    </Accordion.Content>
  )
);

const AccordionComponent = (props: AccordionComponentProps): JSX.Element => {
  const [openSection, setOpenSection] = useState<string | string[]>([]);
  const onOpenSectionsChanged = (val: string | string[]) => {
    setOpenSection(val);
  };
  //Maps the supplied props to an array of AccordionItem elements
  const getContent = (props: AccordionComponentProps) => {
    return props.sections.map((prop: AccordionComponentProp, index: number) => (
      <Accordion.Item
        responsive="true"
        className={`AccordionItem ${props.options?.contentClass || ""}`}
        value={prop.identifier ? `item-${prop.identifier}` : `item-${index}`}
        key={`item-${index}`}
        onClick={prop.onClick}
        data-testid={"accordionitem"}
      >
        <AccordionTrigger> {prop.triggerElement} </AccordionTrigger>
        <AccordionContent>{prop.content}</AccordionContent>
      </Accordion.Item>
    ));
  };
  return (
    <Accordion.Root
      onValueChange={(val) => onOpenSectionsChanged(val)}
      className="AccordionRoot"
      type={props.options?.multiple ? "multiple" : "single"}
      collapsible={props.options?.collapsible ? "true" : "false"}
    >
      {getContent(props)}
    </Accordion.Root>
  );
};
AccordionComponent.displayName = "AccordionComponent";
AccordionTrigger.displayName = "AccordionTrigger";
AccordionContent.displayName = "AccordionContent";
export default AccordionComponent;
